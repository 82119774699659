:root {
  --aheia-orange: #f37321;
}

.aheia_theme {
  position: relative;
  z-index: 50;

  .text-aheia-orange {
    color: var(--aheia-orange);
  }

  .city-links {
    margin-top: 1rem !important;
  }
  .city-links li {
    color: var(--aheia-orange);
  }
  .position-links li,
  .city-links li {
    margin: 0;
    padding: 0;
    line-height: 0.9rem;
  }

  .position-links li:hover,
  .city-links li:hover {
    text-decoration: underline;
  }

  .hero-icons {
    background-color: transparent;
  }
  .hero-icons li {
    max-width: var(--position_type_size);
  }
  .hero-icons li img {
    aspect-ratio: 1 / 1;
    background-color: white;
    border-radius: 100vmax;
    border: 1px solid black;
    border-color: var(--aheia-orange) !important;
  }

  .hero-icons li p {
    margin-top: 8px;
    color: #9da0a8;
  }
  .wrapper {
    .sentence {
      &.text-center {
        text-align: center;
      }
      &.text-white {
        color: white;
      }
    }
  }

  h2 {
    color: var(--aheia-orange);
    font-family: "proxima-nova", sans-serif;
    font-weight: 900;
    border-bottom: 3px solid silver;
    border-bottom: 3px solid var(--Colour2);
    margin-top: 0;
    margin-bottom: 2.4rem;
    padding-left: 15px;
    border-bottom: 3px solid silver;
  }
  h2.plain {
    color: black;
    border: none;
    margin-bottom: 1.5rem;
  }

  h3.uni_name {
    color: var(--aheia-orange);
  }

  .hero-icons {
    max-width: 800px;
  }

  .hero-icons li:hover a p {
    color: #f4a10c !important;
  }
  @media (max-width: 926px) {
    .hero-icons.hide li:nth-child(5) {
      display: none;
    }
  }
  @media (max-width: 498px) {
    .hero-icons.hide li:nth-child(4) {
      display: none;
    }
  }

  @media (max-width: 300px) {
    .hero-icons.hide li:nth-child(3) {
      display: none;
    }
    .word_slider_wrapper {
      font-size: 9px;
      min-width: 250px !important;
      // margin-bottom: 4rem;
    }
  }

  ul.aj_uni_list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    list-style-type: none;
    gap: 10px;
  }

  ul.aj_uni_list li {
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 1.5rem;
  }

  @media (min-width: 768px) {
    ul.aj_uni_list {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 1024px) {
    ul.aj_uni_list {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .flip-card {
    background-color: transparent;
    width: 100%;
    height: 200px;
    perspective: 1000px;
  }

  ul.remove_bullet {
    list-style-type: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .flip-card-inner {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }

  .flip-card:focus {
    outline: 0;
  }

  .flip-card:hover .flip-card-inner,
  .flip-card:focus .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .flip-card-front {
    // background: linear-gradient(to left, #4364f7, #6fb1fc);
    background-color: white;
    color: black;
    border: 1px solid rgb(123, 123, 123);

    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flip-card-back {
    // background: linear-gradient(to right, #4364f7, #6fb1fc);
    background-color: white;
    border: 1px solid rgb(123, 123, 123);
    transform: rotateY(180deg);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
